import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import AppStateProvider from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { SessionProvider } from './components/SessionProvider';
import AppFooter from './components/PreJoinScreens/AppFooter/AppFooter';
import VerifyOTPPage from './components/VerifyOTPPage/VerifyOTPPage';
import ResetPasswordRequestPage from './components/ResetPassword/ResetPasswordRequestPage';
import NewPasswordPage from './components/ResetPassword/NewPasswordPage';

import ConferenceSessionPage from './components/ConferenceSessionPage/ConferenceSessionPage';
import ConferenceJoinByIDPage from './components/ConferenceJoinByIDPage/ConferenceJoinByIDPage';
import AgentVideoApp from './video-apps/AgentVideoApp';
import * as serviceWorker from './serviceWorker.js';
import { isPageEmbedded } from './utilities';
import { EmbeddableProvider } from './components/EmbeddableProvider';

const LoginPageWithFooter = () => {
  return (
    <div style={{ height: '100%' }}>
      <LoginPage />
      <AppFooter />
    </div>
  );
};

const VerifyOTPPageWithFooter = () => {
  return (
    <div style={{ height: '100%' }}>
      <VerifyOTPPage />
      <AppFooter />
    </div>
  );
};

let defaultRoutePath = isPageEmbedded ? '/join' : '/';

ReactDOM.render(
  <EmbeddableProvider>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppStateProvider>
          <SessionProvider>
            <Switch>
              {!isPageEmbedded && (
                <PrivateRoute exact path="/">
                  <AgentVideoApp />
                </PrivateRoute>
              )}
              {isPageEmbedded && (
                <PrivateRoute exact path="/">
                  <ConferenceJoinByIDPage />
                </PrivateRoute>
              )}
              <PrivateRoute path="/room/:URLRoomName">
                <AgentVideoApp />
              </PrivateRoute>
              <Route path="/join">
                <ConferenceJoinByIDPage />
              </Route>
              <Route path="/conference/session/:token">
                <ConferenceSessionPage />
              </Route>
              <Route path="/forgot/password">
                <ResetPasswordRequestPage />
              </Route>
              <Route path="/reset_password_request">
                <NewPasswordPage />
              </Route>
              <Route path="/login">
                <LoginPageWithFooter />
              </Route>
              <Route path="/verify-otp">
                <VerifyOTPPageWithFooter />
              </Route>
              <Redirect to={defaultRoutePath} />
            </Switch>
          </SessionProvider>
        </AppStateProvider>
      </Router>
    </MuiThemeProvider>
  </EmbeddableProvider>,
  document.getElementById('root')
);

const onServiceWorkerUpdated = registration => {
  console.log('REGISTRATION::onServiceWorkerUpdated', registration);
  window.location.reload();
};

serviceWorker.register({ onUpdate: onServiceWorkerUpdated });
