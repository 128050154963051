import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { isNil } from 'lodash';
import { INIT_IFRAME } from '../../embed/eventTypes';
import { verifyEmbeddable } from '../../apis/embeddable/embeddable-api';
import { isPageEmbedded } from '../../utilities';

/*
 *  The hooks used by the EmbeddableProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'EmbeddableProvider/' directory are intended to be used by the EmbeddableProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IEmbeddableContext {}

export const EmbeddableContext = createContext<IEmbeddableContext>(null!);

interface EmbeddableProviderProps {
  children: ReactNode;
}

export function EmbeddableProvider({ children }: EmbeddableProviderProps) {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [clientId, setClientId] = useState(undefined);
  const [codename, setCodename] = useState(undefined);
  const [parentUrl, setParentUrl] = useState(undefined);
  const isInsideIframe = isPageEmbedded;
  const receiveShimMessage = event => {
    const { type } = event.data;
    if (type !== INIT_IFRAME) {
      return;
    }
    const { clientId, codename, parentUrl } = (event.data && event.data.value) || {};
    setClientId(clientId);
    setCodename(codename);
    setParentUrl(parentUrl);

    if (!isNil(clientId)) {
      window.removeEventListener('message', receiveShimMessage, false);
    }
  };

  useEffect(() => {
    if (!isInsideIframe) {
      setIsValid(true);
      setIsVerified(true);
      return;
    }
    if (isNil(clientId) || isNil(codename) || isNil(parentUrl)) {
      return;
    }
    setIsVerifying(true);
    verifyEmbeddable(clientId!, codename!, parentUrl!)
      .then(response => {
        setIsValid(true);
        setIsVerified(true);
        setIsVerifying(false);
      })
      .catch(error => {
        // console.error('EmbeddableProvider::verifyEmbeddable', error);
        setIsValid(false);
        setIsVerified(true);
        setIsVerifying(false);
        // console.error('VerifyEmbeddable::Error', error);
      });
  }, [parentUrl, clientId, codename]);

  useEffect(() => {
    if (!isInsideIframe) {
      return;
    }
    window.addEventListener('message', receiveShimMessage, false);
    return () => {
      window.removeEventListener('message', receiveShimMessage, false);
    };
  }, []);
  return (
    <EmbeddableContext.Provider value={{}}>
      {isVerifying && <CircularProgress />}
      {isValid && isVerified && children}
      {isValid && !isVerified && <p>Invalid Embeddable Key</p>}
    </EmbeddableContext.Provider>
  );
}
