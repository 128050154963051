import { useCallback, useEffect, useState } from 'react';
import { AgentDetails } from '../../types';
import { StorageWrapper } from '../../storage-wrapper';
import {
  login,
  verifyOneTimePasscode,
  resetPasswordRequest,
  setNewPasswordRequest,
  validateNewPasswordTokenRequest,
  resendOTP,
} from '../../apis/auth/auth-api';

export function getErrorMessage(message?: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function useVolletoAuth() {
  const userModel = StorageWrapper.getUserModel();
  const [user, setUser] = useState<AgentDetails | undefined>(userModel);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isIntermediateAuthReady, setIsIntermediateAuthReady] = useState(false);

  useEffect(() => {
    const authToken = StorageWrapper.getAuthToken();
    if (authToken) {
      setIsAuthReady(true);
    }
    const interAuthToken = StorageWrapper.getIntermediateToken();
    if (interAuthToken) {
      setIsIntermediateAuthReady(true);
    }
  }, []);

  const onIntermediateToken = (token: any) => {
    StorageWrapper.storeIntermediateToken(token);
    setIsIntermediateAuthReady(true);
    setIsAuthReady(false);
  };

  const onFinalToken = useCallback((token: any, user: AgentDetails) => {
    StorageWrapper.storeUserModel(user);
    StorageWrapper.storeAuthToken(token);
    StorageWrapper.storeIntermediateToken(undefined);
    setUser(user as any);
    setIsIntermediateAuthReady(false);
    setIsAuthReady(true);
  }, []);

  const signIn = useCallback(
    (email: string, password: string) => {
      // console.log('SignIn', email, password);
      return login(email, password).then(res => {
        // console.log('SignIn::RES', res);
        // if Login OTP disabled
        const { token, user } = res;
        if (token && user) {
          onFinalToken(token, user);
        } else if (res?.token) {
          // if Login OTP disabled
          const { token } = res;
          onIntermediateToken(token);
        } else {
          StorageWrapper.storeIntermediateToken(undefined);
          throw new Error(getErrorMessage(res?.error?.message));
        }
      });
    },
    [onFinalToken]
  );

  const verifyOTP = useCallback(
    (code: string) => {
      return verifyOneTimePasscode(code).then(res => {
        if (res?.token) {
          const { token, user } = res;
          onFinalToken(token, user);
        } else {
          throw new Error(getErrorMessage(res?.error?.message));
        }
      });
    },
    [onFinalToken]
  );

  const resendOTPRequest = useCallback(() => {
    return resendOTP();
  }, []);

  const resetPasswordRequestForAgent = useCallback((email: string) => {
    return resetPasswordRequest(email).then(res => {
      // console.log('resetPasswordRequestForAgent::RES', res);
    });
  }, []);

  const validateNewPasswordToken = useCallback((token: string) => {
    return validateNewPasswordTokenRequest(token);
  }, []);

  const setNewPasswordRequestForAgent = useCallback((perishableToken: string, newPassword: string) => {
    return setNewPasswordRequest(perishableToken, newPassword).then(res => {
      // console.log('resetPasswordRequestForAgent::RES', res);
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    StorageWrapper.cleanAgentData();
    return Promise.resolve();
  }, []);
  // console.log('VOLLETO_AUTH', user, isAuthReady, roomType);
  return {
    user,
    isAuthReady,
    isIntermediateAuthReady,
    signIn,
    signOut,
    verifyOTP,
    resendOTPRequest,
    resetPasswordRequestForAgent,
    setNewPasswordRequestForAgent,
    validateNewPasswordToken,
  };
}
