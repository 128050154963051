export const INIT_IFRAME = 'INIT_IFRAME';
// export const SET_COOKIE = 'SET_COOKIE';
// export const START_AUTH_FLOW = 'START_AUTH_FLOW';
// export const START_SIGNUP_FLOW = 'START_SIGNUP_FLOW';
// export const CANCEL_FLOW = 'CANCEL_FLOW';
// export const FINISH_FLOW = 'FINISH_FLOW';

// export const TOGGLE_CHAT = 'TOGGLE_CHAT';

// export const GET_CURRENT_USER_VIA_JWT = 'GET_CURRENT_USER_VIA_JWT';

// export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
// export const FETCH_CURRENT_USER_PENDING = 'FETCH_CURRENT_USER_PENDING';
// export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
// export const FETCH_CURRENT_USER_FAILED = 'FETCH_CURRENT_USER_FAILED';
// export const FETCH_CURRENT_USER_CANCELLED = 'FETCH_CURRENT_USER_CANCELLED';

// export const SET_END_USER_ATTRIBUTE = 'SET_END_USER_ATTRIBUTE';

// export const VERIFY_EMAIL_TOKEN = 'VERIFY_EMAIL_TOKEN';
// export const VERIFY_EMAIL_TOKEN_SUCCESS = 'VERIFY_EMAIL_TOKEN_SUCCESS';

// export const CHANGE_CONTAINER_CLASS = 'CHANGE_CONTAINER_CLASS';
// export const CHANGE_CONTAINER_CLASS_DONE = 'CHANGE_CONTAINER_CLASS_DONE';

// export const SET_DEBUG_MODE = 'SET_DEBUG_MODE';

export const DOMAIN_NOT_ALLOWED = 'DOMAIN_NOT_ALLOWED';

export const BOOTSTRAP_DONE = 'BOOTSTRAP_DONE';
