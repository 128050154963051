import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import LinearProgress from '@material-ui/core/LinearProgress';

import useSessionContext from '../../../hooks/useSessionContext/useSessionContext';
import { useParams } from 'react-router-dom';
// import { TwilioError } from 'twilio-video';
import { UserActivityType } from '../../../types';
import useLogActivities from '../../../state/useLogActivities/useLogActivities';
import CopySessionUrlButton from '../../Buttons/CopySessionUrlButton/CopySessionUrlButton';

const useStyles = makeStyles((theme: Theme) => ({
  explanatoryText: {
    fontSize: '14px',
    color: '#5f6368',
    fontWeight: 100,
  },
  backButton: {
    color: '#5f6368',
    fontWeight: 100,
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
    margin: '10px',
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface GenerateMeetingUrlProps {
  // name?: string;
  // setStep: (step: Steps) => void;
  cancelAction: () => void;
}

export default function GenerateMeetingUrl({ cancelAction }: GenerateMeetingUrlProps) {
  const classes = useStyles();
  const { customer: customerDetails } = useSessionContext();
  const { createRoom, fetchCustomerSession } = useAppState();
  const { logUserActivity } = useLogActivities();
  const { token: sessionToken } = useParams();
  const { setCustomer } = useSessionContext();
  const [isSessionCreated, setIsSessionCreated] = useState<boolean>(false);
  const [isSessionBeingCreated, setIsSessionBeingCreated] = useState<boolean>(false);

  const createConfSession = async () => {
    const { customer } = await createRoom(customerDetails!);
    setCustomer(customer);
    logUserActivity(UserActivityType.MeetingSessionGenerated).then();
  };

  useEffect(() => {
    if (isSessionCreated || isSessionBeingCreated) {
      return;
    }
    setIsSessionBeingCreated(true);
    createConfSession().then(() => {
      setIsSessionCreated(true);
      setIsSessionBeingCreated(false);
    });
  }, [sessionToken, fetchCustomerSession, createConfSession, isSessionBeingCreated, isSessionCreated]);

  return (
    <>
      {isSessionBeingCreated && <LinearProgress />}
      {isSessionCreated && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Here's the link to your meeting
          </Typography>
          <Typography variant="h5" className={classes.explanatoryText}>
            Copy this link and send it to people you want to meet with. Be sure to save it so you can use it later, too.
          </Typography>

          <Grid container justify="center">
            <Grid item md={5} sm={12} xs={12}>
              <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
                {
                  <div className={classes.joinButtons}>
                    <CopySessionUrlButton />
                  </div>
                }
                <Button className={classes.backButton} onClick={cancelAction} variant="contained">
                  BACK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
