import { AUTH_TOKEN, CURRENT_SESSION_ID, CUSTOMER_MODEL, INTERMEDIATE_TOKEN, USER_MODEL } from './storage-constants';
import { isNil, isObject, isString } from 'lodash';
import { CustomerDetails } from './types';

class StorageWrapper {
  static storeItem(storage, key, value) {
    if (isNil(value)) {
      storage.removeItem(key);
      return;
    }
    let _value = value;
    if (isObject(value)) {
      _value = JSON.stringify(value);
    }
    storage.setItem(key, _value);
  }

  static getItem(storage, key) {
    return storage.getItem(key);
  }

  static getObjectItem(storage, key) {
    const val = storage.getItem(key);
    return isString(val) ? JSON.parse(val) : val;
  }

  //   PUBLIC FUNCTIONS

  static getIntermediateToken() {
    return StorageWrapper.getItem(localStorage, INTERMEDIATE_TOKEN);
  }

  static storeIntermediateToken(token?: string) {
    StorageWrapper.storeItem(localStorage, INTERMEDIATE_TOKEN, token);
  }

  static getAuthToken() {
    return StorageWrapper.getItem(localStorage, AUTH_TOKEN);
  }

  static storeAuthToken(token?: string) {
    StorageWrapper.storeItem(localStorage, AUTH_TOKEN, token);
  }

  static getUserModel() {
    return StorageWrapper.getObjectItem(localStorage, USER_MODEL);
  }

  static storeUserModel(model: any) {
    StorageWrapper.storeItem(localStorage, USER_MODEL, model);
  }

  static getCustomerModel(): CustomerDetails {
    return StorageWrapper.getObjectItem(localStorage, CUSTOMER_MODEL);
  }

  static storeCustomerModel(model: any) {
    StorageWrapper.storeItem(localStorage, CUSTOMER_MODEL, model);
  }

  static getCurrentSessionId() {
    return StorageWrapper.getItem(localStorage, CURRENT_SESSION_ID);
  }

  static storeCurrentSessionId(sessionId?: string) {
    StorageWrapper.storeItem(localStorage, CURRENT_SESSION_ID, sessionId);
  }

  static cleanAgentData() {
    StorageWrapper.storeAuthToken(undefined);
    StorageWrapper.storeUserModel(undefined);
    StorageWrapper.storeCurrentSessionId(undefined);
  }
}

export { StorageWrapper };
